import icon from "../images/icons/vows.png";

const obj = {
  title: "Wedding Vows",
  to: "/ai/wedding/vows",
  api: "/ai/wedding/addon",
  getApi: "/ai/wedding/vows",
  historyType: "vows",
  toolType: "addon",
  buttonTitle1: "Create my vows",
  buttonTitle2: "Refine my vows",
  textTitle: "Vows",
  output: {
    title: "Preview",
    Icon: false,
    color: "blue",
  },
  icon: icon,
  prompts: [
    {
      title: "Step 1: Share Essential Details",
      id: "information",
      desc: "Answer questions about your partner and relationship to get custom-written draft vows. Share thoughtful and specific details for the best results.",
      instruction: `
      <li>Get your draft: Answer questions.</li> 
      <li>Make it yours: Give feedback or tap vows to edit.</li> 
      <li>Ready to share: Export for the big day.</li>`,
      prompts: [
        {
          title: "Your name",
          attr: "name",
          value: "",
          //placeholder: "My name is...",
          label: "",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "Your partner’s name",
          attr: "partnerName",
          value: "",
          //placeholder: "My partner’s name is...",
          label: "",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "Preferred tone of your vows (E.g. Sentimental, Humorous, Heartfelt, or a Mix)",
          attr: "tone",
          value: "",
          //placeholder: "I would like my vows to be...",
          //label:
            //"Example: Sentimental; humorous; heartfelt; or a mix of tones, like sentimental and humorous",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "Preferred length of vows? Go for Short & Sweet (1-2 min) or More Detailed (2-3 min)",
          attr: "vowsLength",
          value: "",
          //placeholder: "I would like to...",
          //label:
            //"Example: Choose from Short & Sweet (1-2 min) or Longer & More Personal (2-3 min)",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title:
            "How did your relationship begin? Describe a moment that stands out from the early days of your relationship",
          attr: "loveStory",
          value: "",
          //placeholder: "A story that comes to mind is...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title: "What do you love most about your partner? What qualities do they have that you admire?",
          attr: "partnerQualities",
          value: "",
          //placeholder: "The qualities I love are...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title: "How has your partner inspired you?",
          attr: "partnerInspire",
          value: "",
          //placeholder: "My partner has influenced or inspired me by...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title:
            "List 3 to 6 specific promises that you want to make to your partner. Include a mix of heartfelt and playful promises.",
          attr: "promises",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: true,
          error: "",
        },
        //{
          //title:
            //"Do you want to include any cultural or religious traditions?",
          //attr: "culturalTraditions",
          //value: "",
          //placeholder: "I would like to...",
          //label: "If 'none', leave blank.",
          //type: "textarea",
          //maxLength: 300,
          //required: false,
          //error: "",
        //},
        {
          title: "How do you envision your future together? What shared dreams do you see coming true?",
          attr: "partnerFuture",
          value: "",
          //placeholder: "I see our future...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title:
            "Any special quotes, poems, or songs?",
          attr: "quoteResonate",
          value: "",
          //placeholder: "One that comes to mind is...",
          label: "",
          type: "textarea",
          maxLength: 300,
          required: false,
          error: "",
        },
        {
          title:
            "How would you like to close your vows? What love-filled final message would you like to give?",
          attr: "finalWords",
          value: "",
          //placeholder: "I would like to include...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
      ],
    },
    {
      title: "Step 2: Refine Vows",
      id: "refine",
      desc: "Tweak your vows for the perfect fit. Adjust the tone, modify words, or suggest any edits you want. Your vows will update instantly.",
      instruction: "Number of automated revisions left:",
      prompts: [
        {
          title:
            "What changes would you like?",
          attr: "feedbackdescription",
          value: "",
          //placeholder: "Please update the...",
          label: "",
          type: "textarea",
          minRows: 5,
          maxLength: 800,
          required: true,
          error: "",
        },
      ],
    },
  ],
};

export default obj;
