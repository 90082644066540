import icon from "../images/icons/groom.png";

const obj = {
  title: "Groom",
  to: "/ai/wedding/groom",
  api: "/ai/wedding",
  getApi: "/ai/wedding/groom",
  historyType: "groom",
  toolType: "speech",
  buttonTitle1: "Draft my speech",
  buttonTitle2: "Refine my speech",
  textTitle: "Speech",
  output: {
    title: "Speech Preview",
    Icon: false,
    color: "blue",
  },
  icon: icon,
  prompts: [
    {
      title: "Step 1: Provide Key Information",
      id: "information",
      desc: "Answer questions to get a custom-written draft toast. Share fun stories and specific details for the best results.",
      instruction: `<!-- <h1>Craft a memorable toast in a few simple steps:</h1> -->
      <li>Get your draft: Answer questions.</li> 
      <li>Make it yours: Give feedback or tap speech to edit.</li> 
      <li>Ready to share: Export for the big day.</li>`,
      prompts: [
        {
          title: "Groom’s name",
          attr: "name",
          value: "",
          //placeholder: "My name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        {
          title: "Groom's partner’s name",
          attr: "partnerName",
          value: "",
          //placeholder: "My partner’s name is...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        {
          title: "Names of the Groom’s parents",
          attr: "groomParents",
          value: "",
          //placeholder: "My parents’ names are...",
          label: "",
          type: "text",
          maxLength: 100,
          required: false,
          error: "",
        },
        {
          title: "Names of the partner's parents",
          attr: "partnerParents",
          value: "",
          //placeholder: "Their names are...",
          label: "",
          type: "text",
          maxLength: 100,
          required: false,
          error: "",
        },
        {
          title: "What tone are you aiming for in your speech? (E.g. Heartfelt, funny, formal, or a blend)",
          attr: "tone",
          value: "",
          //placeholder: "I would like the speech to be...",
          //label:
            //"Example: lighthearted and humorous, sentimental and heartfelt",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "Three words that describe your partner",
          attr: "groomPartnerWords",
          value: "",
          //placeholder: "My partner is...",
          label: "",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title:
            "What initially attracted you to your partner? When did you realize you were in love?",
          attr: "partnerMoment",
          value: "",
          //placeholder: "I was attracted to...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title:
            "Describe your partner's best qualities. Can you share a moment when these qualities truly shone through?",
          attr: "partnerMemory",
          value: "",
          //placeholder: "One memorable moment that springs to mind is...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title:
            "How has your life changed since meeting your partner? What are your hopes for your future together?",
          attr: "future",
          value: "",
          //placeholder: "My life...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title:
            "Share a funny or heartwarming story about your relationship",
          attr: "funnyStory",
          value: "",
          //placeholder: "The proposal...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title:
            "Can you think of a time when you faced something together as a couple that truly highlighted your love, strength, or connection?",
          attr: "milestones",
          value: "",
          //placeholder: "One significant milestone is...",
          label: "Examples: having a child or children together",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        //{
          //title:
            //"What qualities or characteristics do you admire most in your partner, and how do they complement your own personality or values?",
          //attr: "partnerQualities",
          //value: "",
          //placeholder: "I most admire...",
          //label: "",
          //type: "textarea",
          //maxLength: 400,
          //required: true,
          //error: "",
        //},
        //{
          //title:
            //"Are there any cultural or personal traditions that you will be incorporating into your wedding or marriage?",
          //attr: "culturalTradition",
          //value: "",
          //placeholder: "I would like to incorporate...",
          //label: "If 'none', leave blank.",
          //type: "textarea",
          //maxLength: 200,
          //required: false,
          //error: "",
        //},
        {
          title:
            "What would you like to say to your parents for their love and support?",
          attr: "parentsThank",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title: "Is there a message you'd like to share with your in-laws?",
          attr: "inLawsThank",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title:
            "Is there anyone else you'd like to thank in your speech?",
          attr: "friendsThank",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title:
            "Do you have any special promises or words of love you'd like to express directly to your partner?",
          attr: "partnerThank",
          value: "",
          //placeholder: "I would like to...",
          label: "",
          type: "textarea",
          maxLength: 600,
          required: false,
          error: "",
        },
      ],
    },
    {
      title: "Step 2: Refine Speech",
      id: "refine",
      desc: "Tweak your speech for the perfect fit. Adjust the tone, add stories, or suggest any edits you want. Your speech will update instantly.",
      instruction: "Number of automated revisions remaining:",
      prompts: [
        {
          title:
            "What changes would you like?",
          attr: "feedbackdescription",
          value: "",
          //placeholder: "Please update the...",
          label: "",
          type: "textarea",
          minRows: 5,
          maxLength: 800,
          required: true,
          error: "",
        },
      ],
    },
  ],
};

export default obj;
