import { ThemeProvider } from "styled-components";
import React, { Component } from "react";

import { Provider } from "mobx-react";
import { observer } from "mobx-react";

import AppStore from "./store";
import colors from "tailwindcss/colors";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
// import Search from './Search'
import Pricing from "./Pricing"; // <--- Add this line

import Dashboard from "./Dashboard";

import Tool from "./Core/Tool";
import Chat from "./Core/Chat";

import Login from "./Login/Login";
import Forgot from "./Login/Forgot";
import Reset from "./Login/Reset";
import Confirm from "./Login/Confirm";

import Profile from "./Profile/";
import LoginSuccess from "./Login/Success";
import Tutorial from "./Tutorial";

import "./App.scss";
import { NotificationsContainer } from "./Core/Notification";

if (!window.store) {
  window.store = new AppStore();
}

@observer
class App extends Component {
  render() {
    return (
      <ThemeProvider theme={colors}>
        <Provider store={window.store}>
          <Router>
            {window.store.redirect ? (
              <Redirect to={window.store.redirect} />
            ) : null}
            {window.store.isLoggedIn ? (
              <>
                {window.store.profile.status === "active" ? (
                  <>
                    {" "}
                    {/*  Logged in with plan */}
                    <Switch>
                      <Route path="/writing/document">
                        <div />
                      </Route>
                      <Route component={Header} />
                    </Switch>
                    <Switch>
                      <Route path="/" exact component={Dashboard} />
                      {/* <Route path="/search" exact component={Search} /> */}

                      <Route path="/ai/">
                        <Switch>
                          <Route path="/ai/code/debugging" component={Chat} />
                          <Route component={Tool} />
                        </Switch>
                      </Route>
                      <Route path="/my-profile" component={Profile} />
                      <Route path="/tutorial" component={Tutorial} />
                      <Route path="/signup/failed" component={Profile} />
                      <Route path="/signup/success" component={LoginSuccess} />
                      <Route path="/signup/success" component={LoginSuccess} />
                    </Switch>
                  </>
                ) : !window.store.profile.emailConfirmed ? (
                  <>
                    {" "}
                    {/* Logged in but no plan */}
                    <Switch>
                      <Route
                        path="/confirm-account"
                        render={() => (
                          <Confirm
                            email={window.store.profile.email}
                            isFromRoute={true}
                          />
                        )}
                      />
                      <Redirect to="/confirm-account" />
                      {/* <Route>
                        <Confirm />
                      </Route> */}
                      {/* <Route path="/login" component={Login} /> */}
                    </Switch>
                  </>
                ) : (
                  <>
                    {" "}
                    {/* Logged in but no plan */}
                    <Switch>
                      <Route path="/signup/success" component={LoginSuccess} />
                      <Route>
                        {/* do not skip stripe and pricing page */}
                        <Pricing />
                        {/* skip stripe and pricing page */}
                        {/* <Dashboard /> */}
                      </Route>
                    </Switch>
                  </>
                )}{" "}
              </>
            ) : (
              <>
                {" "}
                {/*  Not Logged In */}
                <Switch>
                  <Route path="/forgot" component={Forgot} />
                  <Route path="/reset" component={Reset} />
                  <Route path="/confirm-account" component={Confirm} />
                  <Route path="/" exact>
                    <Redirect to="/login" />
                  </Route>
                  <Route path="/" component={Login} />
                </Switch>
              </>
            )}
            <Footer />
          </Router>
        </Provider>
        <NotificationsContainer />
      </ThemeProvider>
    );
  }
}

export default App;
