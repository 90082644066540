import icon from "../images/icons/poem.png";

const obj = {
  title: "Bespoke Poem",
  to: "/ai/wedding/poem",
  api: "/ai/wedding/addon",
  getApi: "/ai/wedding/poem",
  historyType: "poem",
  toolType: "addon",
  buttonTitle1: "Create my wedding wishes",
  buttonTitle2: "Refine my wedding wishes",
  textTitle: "Wedding Wishes and Messages",
  output: {
    title: "Preview",
    Icon: false,
    color: "blue",
  },
  icon: icon,
  prompts: [
    {
      title: "Step 1: Share Key Details",
      id: "information",
      desc: "Answer questions about the couple to get a personalised wedding message.",
      instruction: `<!-- <h1>Craft your message in a few simple steps:</h1> --> 
      <li>Get your draft: Answer questions.</li> 
      <li>Make it yours: Give feedback or tap message to edit.</li> 
      <li>Ready to share: Export for the big day.</li>`,
      prompts: [
        {
          title: "What are the names of the newlyweds?",
          attr: "name",
          value: "",
          //placeholder: "The newlyweds’ names are...",
          label: "",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "What is your name (and your partner's name, if applicable)?",
          attr: "yourName",
          value: "",
          //placeholder: "The card is coming from...",
          label: "",
          type: "text",
          maxLength: 100,
          required: true,
          error: "",
        },
        {
          title: "How are you related to the couple?",
          attr: "relation",
          value: "",
          //placeholder: "I am...",
          label: "",
          type: "text",
          maxLength: 40,
          required: true,
          error: "",
        },
        //{
          //title:
            //"Would you like to create a personalized card message or a poem?",
          //attr: "poemOrMessage",
          //value: "",
          //placeholder: "I would like to generate a...",
          //label: "Example: Personalized poem; Unique Message",
          //type: "text",
          //maxLength: 100,
          //required: true,
          //error: "",
        //},
        //{
          //title: "What tone would you prefer? (E.g. Romantic, humorous, sentimental, or a blend)",
          //attr: "tone",
          //value: "",
          //placeholder: "I would like the tone to be...",
          //label:
            //"Example: Sentimental; humorous; heartfelt; or a mix of tones, like sentimental and humorous",
          //type: "text",
          //maxLength: 300,
          //required: false,
          //error: "",
        //},
        //{
          //title: "Do you have a preferred length? (short: 20-50 words, medium: 50-150 words, long: 150-300 words)",
          //attr: "poemLength",
          //value: "",
          //placeholder: "I would like it to be...",
          //label:
            //"Choose from a short poem or message, up to a 300-word output, or anywhere in between",
          //type: "textarea",
          //maxLength: 300,
          //required: false,
          //error: "",
        //},
        {
          title:
            "Do you have a special memory or reflection about the couple you'd like included",
          attr: "memories",
          value: "",
          //placeholder: "I would like to reference...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        {
          title:
            "Do you have specific well-wishes or hopes for the couple’s future?",
          attr: "topics",
          value: "",
          //placeholder: "I would like to reference...",
          label: "",
          type: "textarea",
          maxLength: 500,
          required: false,
          error: "",
        },
        //{
          //title:
          //  "Would you like to weave any special details or memories into your poem or message?",
          //attr: "interest",
          //value: "",
          //placeholder: "The couple’s...",
          //label: "",
          //type: "textarea",
          //maxLength: 300,
          //required: false,
          //error: "",
        //},
      ],
    },
    {
      title: "Step 2: Polish Your Message",
      id: "refine",
      desc: "Tweak your message for the perfect fit. Adjust the tone, wording, or suggest any edits you want. Your message will update instantly.",
      //instruction: "Number of automated revisions remaining:",
      prompts: [
        {
          title:
            "What changes would you like?",
          attr: "feedbackdescription",
          value: "",
          //placeholder: "Please update the...",
          label: "",
          type: "textarea",
          minRows: 5,
          maxLength: 800,
          required: true,
          error: "",
        },
      ],
    },
  ],
};

export default obj;
